<template>
  <div>
    <a-tabs v-model="radioValue" class="approve-tab" default-active-key="1">
      <a-tab-pane key="1">
        <span slot="tab">
          <a-badge v-if="userInfo().unprocessed" :count="userInfo().unprocessed.changeApplyCount || 0">
            <span>可工作数量审批</span>
          </a-badge>
        </span>
        <approveOne />
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab">
          <a-badge v-if="userInfo().unprocessed" :count="userInfo().unprocessed.userLeaveCount || 0">
            <span>请假审批</span>
          </a-badge>
        </span>
        <approveTwo ref="approveTwo" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import approveOne from './approve-option-one'
import approveTwo from './approve-option-two'
import { mapGetters } from 'vuex'
import { USER_INFO, ACCESS_TOKEN } from '@/store/mutation-types'
import Vue from 'vue'
export default {
  components: { approveOne, approveTwo },
  data() {
    return {
      radioValue: '1',
      /* userInfo: {} */
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  watch: {
    radioValue() {
      if (this.radioValue == '2') {
        this.$nextTick(() => {
          if (this.$refs.approveTwo) {
            this.$refs.approveTwo.getTableData()
          }
        })
      }
    }
  },
  methods: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.radioValue = this.$route.params.type + ''
    /* this.userInfo = Vue.ls.get(USER_INFO) */
  }
}
</script>

<style lang="less" scoped>
/deep/ .approve-tab .ant-tabs-bar {
  margin: 0 48px 0 48px !important;
}
.red {
  color: red;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
/deep/ .list-content {
  p {
    margin-bottom: 0 !important;
  }
}
.file-span {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: var(--theme-color);
  }
}
/deep/ .ant-badge-count {
  top: -5px !important;
  right: -5px !important;
}
</style>
