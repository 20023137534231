var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-tabs",
        {
          staticClass: "approve-tab",
          attrs: { "default-active-key": "1" },
          model: {
            value: _vm.radioValue,
            callback: function ($$v) {
              _vm.radioValue = $$v
            },
            expression: "radioValue",
          },
        },
        [
          _c(
            "a-tab-pane",
            { key: "1" },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _vm.userInfo().unprocessed
                    ? _c(
                        "a-badge",
                        {
                          attrs: {
                            count:
                              _vm.userInfo().unprocessed.changeApplyCount || 0,
                          },
                        },
                        [_c("span", [_vm._v("可工作数量审批")])]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("approveOne"),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2" },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _vm.userInfo().unprocessed
                    ? _c(
                        "a-badge",
                        {
                          attrs: {
                            count:
                              _vm.userInfo().unprocessed.userLeaveCount || 0,
                          },
                        },
                        [_c("span", [_vm._v("请假审批")])]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("approveTwo", { ref: "approveTwo" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }